class Global {
    constructor(baseUseClass) {
        this.baseUseClass = baseUseClass;
        this.init = this.init.bind(this);
        this.anchorjump = this.anchorjump.bind(this);
        this.applyDataLinkListener = this.applyDataLinkListener.bind(this);
        this.provideInnerHeight = this.provideInnerHeight.bind(this);
        window.onhashchange = this.anchorjump;
    }

    init() {
        this.anchorjump();
        this.applyJQueryExtensions();
        this.applyDataLinkListener();
        this.initFocus();
        $(window).on('resizeend', () => {
            this.provideInnerHeight();
        });
        $(window).on('faqLoaded', () => {
           this.anchorjump();
        });
        let debounce;
        $(window).on('tariffTableLoaded', () => {
            clearTimeout(debounce);
            debounce = setTimeout(() => {
                this.anchorjump();
            }, 250);
        });
    }

    /**
     * Provides the inner height of the document as CSS property - useful for iOS fixes etc.
     */
    provideInnerHeight() {
        document.documentElement.style.setProperty('--inner-height', `${window.innerHeight}px`);
    }

    /**
     * Initializes the events to set the focus to elements
     */
    initFocus() {
        $(document).on('keyup click', () => {
            setTimeout(() =>  this.setFocusToElement(), 150)
            }) ;
        $(window).on('resize', this.setFocusToElement);
        const observer = new MutationObserver(this.setFocusToElement);

        const observerOptions = {
            childList: true,
            subtree: true,
        };
        observer.observe(document.querySelector('body'), observerOptions);
    }

    /**
     * Set the optical focus to an element
     * @const outline {HTMLDivElement} - the outline we want to apply
     * @const focused {Element} - the element that has been focused
     */
    setFocusToElement = () => {
        const insideFixedContainer = (element) => new Promise((resolve) => {
            const isFixed = (parent) => window.getComputedStyle(parent).position === 'fixed';
            const recursive = (candidate) => {
                if (candidate && isFixed(candidate)) {
                    resolve(true);
                    return;
                }
                if (!candidate) {
                    resolve(false);
                    return;
                }
                recursive(candidate.parentElement);
            };
            recursive(element.parentElement);
        });

        const outlineWidth = 2;
        const outline = $('.focus-elem').get(0);
        if (!outline) return;
        outline.style.display = 'none';
        const focused = $('.focus-visible').get(0);
        if (!focused) return;
        if (focused.offsetHeight < 1) return;
        const padding = focused.tagName === 'INPUT'
                                || focused.tagName === 'TEXTAREA'
                                || focused.classList.contains('SumoSelect') ? 0 : 5;
        const {
            top: focusedTop,
            left: focusedLeft,
            width: focusedWidth,
            height: focusedHeight,
        } = focused.getBoundingClientRect();

        insideFixedContainer(focused).then((result) => {
            outline.style.width = `${focusedWidth + outlineWidth + (padding * 2)}px`;
            outline.style.height = `${focusedHeight + outlineWidth + (padding * 2)}px`;
            outline.style.top = `${focusedTop - padding - (outlineWidth / 2) + (!result ? window.scrollY : 0)}px`;
            outline.style.left = `${focusedLeft - padding - (outlineWidth / 2) + window.scrollX}px`;

            const grandParent = focused.parentElement.parentElement;
            let borderRadius = 6;
            if (
                grandParent.classList.contains('cmp-blockteaser')
                || grandParent.classList.contains('cmp-blockteaser-with-image')
            ) {
                borderRadius = this.baseUseClass.isMobile() ? 4 : 16;
            }

            outline.style.borderRadius = `${borderRadius}px`;
            outline.style.position = result ? 'fixed' : 'absolute';
            outline.style.zIndex = result ? '999' : '10';
            outline.style.display = 'unset';
        });
    };

    applyJQueryExtensions() {
        if (typeof jQuery !== 'undefined') {
            /** Add a unique ID to the element and return a CSS-Selector pointing to it */
            const composeSelector = (element) => {
                const $element = $(element);
                if ($element.attr('data-jquery-click-target')) return `[data-jquery-click-target="${$element.attr('data-jquery-click-target')}"]`;
                const uniqueId = $element.attr('id') || $element.html().hashCode();
                $element[0].setAttribute('data-jquery-click-target', uniqueId);
                return `[data-jquery-click-target="${uniqueId}"]`;
            };
            /** Overwrite jQuery's "off" */
            jQuery.fn.customOff = function () {
                const params = [...arguments];
                const argumentsContainSelector = typeof params[1] === 'string';

                if (!this.length) {
                    return $();
                }

                if (this[0] === window || this[0] === document) {
                    return $(this).off(...params);
                }

                const jQueryObjects = [];
                let merge;

                $(this).each(function () {
                    const selector = composeSelector(this);
                    jQueryObjects.push(argumentsContainSelector
                        ? $(document).off(params[0], selector, params[2])
                        : $(document).off(params[0], selector, params[1]));
                });

                jQueryObjects.forEach((obj) => {
                    if (!merge) {
                        merge = obj;
                    } else {
                        merge = $.merge(obj, merge);
                    }
                });
                return merge;
            };
            /** Overwrite jQuery's "on" */
            jQuery.fn.customOn = function () {
                const params = [...arguments];
                const argumentsContainSelector = typeof params[1] === 'string';

                if (!this.length) {
                    return $();
                }

                if (this[0] === window || this[0] === document) {
                    return $(this).on(...params);
                }

                const jQueryObjects = [];
                let merge;

                $(this).each(function () {
                    const selector = composeSelector(this);
                    jQueryObjects.push(argumentsContainSelector
                        ? $(document).on(params[0], selector, params[2], params[3])
                        : $(document).on(params[0], selector, params[1], params[2]));
                });

                jQueryObjects.forEach((obj) => {
                    if (!merge) {
                        merge = obj;
                    } else {
                        merge = $.merge(obj, merge);
                    }
                });
                return merge;
            };
            jQuery.fn.onClickEnter = function (callback) {
                /** return jQuery this if callback represents classic function */
                if (callback.toString().indexOf('f') === 0) {
                    return $(this).customOn('click keydown', function (e, param) {
                        if (e.keyCode && e.keyCode !== 13) {
                            return;
                        }
                        callback = callback.bind(this);
                        callback(e, param);
                    });
                } if (callback.toString().indexOf('=>') > 0) {
                    return $(this).customOn('click keydown', (e, param) => {
                        if (e.keyCode && e.keyCode !== 13) {
                            return;
                        }
                        callback(e, param);
                    });
                }
            };
            jQuery.fn.onEnter = function (callback) {
                /** return jQuery this if callback represents classic function */
                if (callback.toString().indexOf('f') === 0) {
                    return $(this).customOn('keydown', function (e) {
                        if (e.keyCode && e.keyCode !== 13) {
                            return;
                        }
                        callback = callback.bind(this);
                        callback(e);
                    });
                } if (callback.toString().indexOf('=>') > 0) {
                    return $(this).customOn('keydown', (e) => {
                        if (e.keyCode && e.keyCode !== 13) {
                            return;
                        }
                        callback(e);
                    });
                }
            };
        }
    }

    anchorjump() {
        // prevent ootb anchor jump
        // animate anchor jump and keep header height into a count
        const {hash} = window.location;
        let itemToScroll = {length: 0};
        try {
            if (hash.indexOf('/') > -1) {
                const split = hash.split('/');
                const last = split[split.length - 1];
                itemToScroll = $(`#${last}, [name="${last}"], [value="${last}"]`);
            } else {
                itemToScroll = $(hash);
            }
        } catch (e) {
            console.warn(`global.js - anchorjump | ${e}`);
        }
        if (itemToScroll.length === 0) {
            return;
        }
        if (itemToScroll[0] && itemToScroll[0].tagName !== 'DIV') {
            itemToScroll = itemToScroll.closest('div');
        }
        // add 5px to header height to take care of headers bottom shadow
        const headerHeight = this.baseUseClass.getFixedContainerHeight() + this.baseUseClass.getBannerHeight() + 15;
        window.scrollTo(window.lastScrollX || 0, window.lastScrollY || 0);
        window.lastScrollY = $(itemToScroll).offset().top - headerHeight;
        $([document.documentElement, document.body]).animate({
            scrollTop: $(itemToScroll).offset().top - headerHeight,
        }, 200);
    }

    applyDataLinkListener() {
        $('option[data-link-href]').parent().on('change', (evt) => {
            this.baseUseClass.callLinkCallback($('option:selected', evt.currentTarget));
        });

        $('[data-link-href]').onClickEnter((evt) => {
            this.baseUseClass.callLinkCallback(evt.currentTarget);
        });
    }
}

export default Global;
