class HardwaresupportIframe {
    hwsOrigin = 'https://eu-dg.knowmax.ai';

    magentaOrigin = 'https://www.magenta.at';

    hwsMagentaBasePath = '/hilfe-service/services/hardwaresupport';

    hwsIframe;

    isLinkShare;

    linkInput;

    constructor() {
        const hwsWrapper = document.getElementById('hws-iframe-wrapper');

        if (hwsWrapper) {
            this.hwsIframe = document.getElementById('hws-iframe');
            this.isLinkShare = hwsWrapper.getAttribute('data-link-share');
            this.isLinkShare = this.isLinkShare && this.isLinkShare === 'true';

            if (this.isLinkShare) {
                const linkInputName = hwsWrapper.getAttribute('data-hws-link-input');
                this.linkInput = document.querySelector(`[name='${linkInputName}']`);
            }

            const hwsBasePath = hwsWrapper.getAttribute('data-hws-base');
            this.hwsMagentaBasePath = hwsBasePath || this.hwsMagentaBasePath;

            console.log(`Linkshare: ${this.isLinkShare}; hwsBasePath: ${this.hwsMagentaBasePath}`);
        }
    }

    init() {
        if (typeof this.hwsIframe === 'undefined') {
            return;
        }

        let iframeUrl = `${this.hwsOrigin}/`;
        const path = this.extractPathFromUrl();

        if (path) {
           iframeUrl = `${iframeUrl}?path=${path}`;
        }

        this.hwsIframe.setAttribute('src', iframeUrl);

        if (this.linkInput) {
            this.linkInput.value = this.getHwsLink(path);
        }

        // Receive message from the child hardware support iframe
        window.addEventListener('message', function (event) {
            // Check the origin of the message for security
            if (event.origin !== this.hwsOrigin) return;

            console.log('Got message from iframe: ', event);

            if (event.data.height) {
                // Update the height of the iframe with the height data sent from the child iframe
                this.hwsIframe.style.height = `${event.data.height}px`;
            }

            if (this.isLinkShare && event.data.path) {
                console.log('Got path Update from child iframe: ', event.data.path);
                if (this.linkInput) {
                    this.linkInput.value = this.getHwsLink(path);
                }
            }
        });

        const receiveHeightFromChild = function () {
            const needheight = 1;
            window.parent.postMessage({height: needheight}, this.hwsOrigin);
        };

        window.addEventListener('resize', receiveHeightFromChild);
    }

    getHwsLink(path) {
        let linkShareUrl = `${this.magentaOrigin}${this.hwsMagentaBasePath}`;
        if (path) {
            linkShareUrl = `${linkShareUrl}${path}`;
        }

        return linkShareUrl;
    }

    extractPathFromUrl() {
        const paths = document.location.pathname.split(this.hwsMagentaBasePath);
        return paths[1] ? paths[1] : null;
    }

    copyHwsLinkToClipboard() {
        const text = this.linkInput.value();
        navigator.clipboard.writeText(text).then(function () {
            console.log('Copying to clipboard was successful!');
        }, function (err) {
            console.error('Could not copy text to clipboard: ', err);
        });
    }

    sendhwsLinkToMail() {
        console.log('Send to mail called');
    }
}

export default HardwaresupportIframe;
